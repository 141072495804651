import React, { memo } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Col } from "reactstrap"

const HeatPumpHeader = memo(() => {
  let pageHeader = React.createRef<HTMLDivElement>()
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)"
      }
      window.addEventListener("scroll", updateScroll)
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll)
      }
    }
  })

  return (
    <>
      <div className="page-header page-header-small">
        <div className="page-header-image" ref={pageHeader}>

            <StaticImage
              style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top' }}
              src="../../assets/img/mitsubishi-heat-pump-installer-kerikeri.png"
              placeholder="blurred"
              formats={["auto", "png", "avif"]}
              alt="HeatPumps KeriKeri"
            />

        </div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 className="title">HeatPump Installs & Servicing</h1>
              <h4>KeriKeri & Surrounds</h4>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
})

export default HeatPumpHeader
