import React, { memo } from "react"
import Layout from "../components/Layout/index"
import SEO from "../components/SEO"
import HeatPumpHeader from "../components/Headers/heatpumpHeader"
import HeatPump2 from "../containers/HeatPump"

interface AboutUsPageProps {}

const HeatPump = memo((props: AboutUsPageProps) => {
  return (
    <Layout title="HeatPumps KeriKeri">
      <HeatPumpHeader />
      <SEO title="About us" />
      <HeatPump2 />
    </Layout>
  )
})

export default HeatPump
