import React, { memo } from "react"
import { Container, Box } from "../../components/ui"
import { Row, Col, Button } from "reactstrap"
import { StaticImage } from "gatsby-plugin-image"
// import * as classes from "./styles.module.scss"

interface Props {}

const HeatPump2 = memo((props: Props) => {
  return (
    <Box paddingY={5}>
      <div className="features-3">
        <Container>
          <Row>
            <Col className="mr-auto ml-auto" md="6">
              <StaticImage
                  src="../../assets/img/heatpump1.jpg"
                  alt="Heat Pump"
                  placeholder="blurred"
                  layout="fullWidth"
              />
            </Col>
            <Col className="mr-auto ml-auto" md="6">
              <h2 className="title">Expert Heat Pump Solutions in Kerikeri</h2>
              {/* <h4 className="description">Leighton Electrical: Your Trusted Partner</h4> */}
              <p>
                At Leighton Electrical, we specialize in providing top-notch heat pump systems 
                and services in the Kerikeri area. Our experienced team ensures your home or 
                business enjoys optimal temperature control year-round.
              </p>
              <div style={{ marginTop: '20px' }}>
                <Button color="primary" href="/enquiry">Enquiry</Button>{' '}
                <Button color="secondary" href="https://heatpump.surgetechnology.co.nz" target="_blank">Online Estimate</Button>
              </div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col className="mr-auto ml-auto" md="8">
            <h3>Why Choose Heat Pumps?</h3>
              <p>
                Heat pumps offer efficient heating and cooling solutions, making them a popular 
                choice in Kerikeri's climate. They are environmentally friendly, cost-effective, 
                and provide consistent comfort.
              </p>
              <h3>Our Services</h3>
              <p>
                We offer a range of services including installation, maintenance, and repair of 
                heat pumps. Each service is tailored to meet the unique needs of your space, 
                ensuring efficient and effective heating and cooling.
              </p>
              <h3>Custom Solutions for Your Space</h3>
              <p>
                Understanding that every home and office is different, we provide custom solutions 
                designed to fit your specific requirements. Our team works closely with you to 
                ensure the best heat pump system for your space.
              </p>
              <h3>Get in Touch</h3>
              <p>
                Contact us today to learn more about our heat pump services in Kerikeri and 
                how we can assist you in achieving the perfect climate control for your space, if you are looking for an online estimate emailed to you, use our online estimator tool and we will have a guide within minutes.
              </p>
              <div style={{ marginTop: '20px' }}>
                <Button color="primary" href="/enquiry">Enquiry</Button>{' '}
                <Button color="secondary" href="https://heatpump.surgetechnology.co.nz" target="_blank">Online Estimate</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Box>
  )
})

export default HeatPump2
